import { createSlice } from '@reduxjs/toolkit';
import {
  allDiseasesAlphabetically,
  searchConditions,
  getPeopleByCondition,
  getTreatmentsByCondition,
  getPlayersByCondition,
  getPhysiciansByCompany,
  getPlayers,
  getDeals,
  getCompany,
  getCompanyPipeline,
  getPhysicians,
} from '../apiCalls/backendUrl';
import Axios from 'axios';
const config = {
  headers: {
    Authorization: `Token 03314be0379da6d92a7ae3e680ab6879f6b00f2c`,
  },
};

const serialize = function(obj) {
  var str = [];
  if(obj){
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
      let string = str.join("&");
      let search = ''
      if(Object.keys(obj).length !== 0){
        search = '?'+string
      }
      return search
  }else{
    return ''
  }
}

const DiseasesReducer = createSlice({
  name: 'diseases',
  initialState: {
    diseaseName: '',
    diseaseId: '',
    alphabetClicked: 'A',
    loading: false,
    diseasesAlphabetically: [],
    listDiseasesModalOpen: false,
    diseasesModalData: [],
    searchDiseasesResults: {},
    searching: false,
    alphabets: [],
    people: [],
    allPlayersList: [],
    players: [],
    playersSummary: [],
    playersByCategory: [],
    playersByCompany: [],
    landmarkTrials: [],
    modalityData: [],
    physiciansByCompany: [],
    loading: false,
    staticLoading: false,
    dataArrangement:[],
    dataArrangementCompanyBySpecialization:[],
    dataArrangementCompanyByPhysicians:[],
    getCount:0,
    getGrandParentCount:0,
    physiciansLevelLeafData:{},
    playersDataLevelLeaf:{},
    allCategoryList:[],
    companyData:'',
    companyPipelineCategory:[],
    singlePhysicianData:{},
    getPhysiciansYears:[],
    dealsSummary:[],
    physiciansSummary:[],
    companySummary:[],
  },
  reducers: {
    setCount(state, action) {
      state.getCount = action.payload;
    },
    setPhysiciansYears(state, action) {
      state.getPhysiciansYears = action.payload;
    },
    setGrandParentCount(state, action) {
      state.getGrandParentCount = action.payload;
    },
    setDiseaseData(state, action) {
      state.diseaseName = action.payload.diseaseName;
      state.diseaseId = action.payload.diseaseId;
    },
    setAlphabetClicked(state, action) {
      state.alphabetClicked = action.payload;
    },
    setSourceLoading(state, action) {
      state.loading = action.payload;
    },
    toggleListDiseasesModal(state, action) {
      state.listDiseasesModalOpen = action.payload;
    },
    setDiseasesModalData(state, action) {
      state.diseasesModalData = [...action.payload];
    },
    setDiseasesAlphabetically(state, action) {
      state.diseasesAlphabetically = [...action.payload];
    },
    setDiseasesSearchResult(state, action) {
      state.searchDiseasesResults = action.payload;
    },
    setSearching(state, action) {
      state.searching = action.payload;
    },
    setAlphabets(state, action) {
      state.alphabets = action.payload;
    },
    setPeopleData(state, action) {
      state.people = action.payload;
    },
    setPlayersData(state, action) {
      state.players = action.payload;
    },
    setAllPlayersList(state, action) {
      state.allPlayersList = action.payload;
    },
    setSinglePhysician(state, action) {
      state.singlePhysicianData = action.payload;
    },
    setAllCategoryList(state, action) {
      state.allCategoryList = action.payload;
    },
    setPlayersSummaryData(state, action) {
      state.playersSummary = action.payload;
    },
    setDealSummaryData(state, action) {
      state.dealsSummary = action.payload;
    },
    setPlayersByCompanyData(state, action) {
      state.playersByCompany = action.payload;
    },
    setPlayersByCategoryData(state, action) {
      state.playersByCategory = action.payload;
    },
    setLandmarkTrialsData(state, action) {
      state.landmarkTrials = action.payload;
    },
    setModalityData(state, action) {
      state.modalityData = action.payload;
    },
    setPhysiciansByCompany(state, action) {
      state.physiciansByCompany = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setStaticLoading(state, action) {
      state.staticLoading = action.payload;
    },
    setPhysiciansByCompanyDispatchLevelLeaf(state, action){
      state.physiciansLevelLeafData = action.payload;
    },
    setPlayersSummaryDataLevelLeaf(state, action) {
      state.playersDataLevelLeaf = action.payload;
    },
    setCompanyPipelineCategory(state, action) {
      state.companyPipelineCategory = action.payload;
    },
    setComapnyData(state, action) {
      state.companyData = action.payload;
    },
    setPhysiciansSummaryData(state, action) {
      state.physiciansSummary = action.payload;
    },
    setCompanySummaryData(state, action) {
      state.companySummary = action.payload;
    },
    setDataArrangement(state, action){
      let {
        dataResults,
        level,
        levelSelect = '',
        levelSelectParent = '',
        limit = 0,
        offset = 0,
      } = action.payload;
      if(level == 'level1'){
        if(offset == 0){
          state.dataArrangement = []
        }
        state.dataArrangement = Array.from(new Set(state.dataArrangement.concat(dataResults).map(JSON.stringify))).map(JSON.parse)
      }
      if(level == 'level2'){
        // state.level_2_key = levelSelect
        state.dataArrangement = state.dataArrangement.map((item,index) => ( 
          item.name == levelSelect ? {
            ...item,
            nextData: Array.from(new Set(item.nextData.concat(dataResults).map(JSON.stringify))).map(JSON.parse),
            offset: limit+offset,
            tot_row_count : state.getCount
          } : {
            ...item, nextData: item.nextData?item.nextData:[]
          }
        ))
      }
      if(level == 'level3'){
        state.dataArrangement = state.dataArrangement.map((item,index) => (
          // item.name == state.level_2_key ? {
            {
              ...item,
            nextData: item.nextData.map((Nitem,index) => (
              Nitem.name == levelSelect && Nitem.parent == levelSelectParent  ? {
                ...Nitem,
                nextData: Array.from(new Set(Nitem.nextData.concat(dataResults).map(JSON.stringify))).map(JSON.parse),
                offset: limit+offset,
                tot_row_count : state.getCount
              } : {
                ...Nitem, nextData: Nitem.nextData?Nitem.nextData:[]
              }
            ))
          // } : {
          //   ...item, nextData: item.nextData?item.nextData:[]
          }
        ))
      }
    },
    setDataArrangementCompanyBySpecialization(state, action){
      let {
        dataResults,
        level,
        levelSelect = '',
        levelSelectParent = '',
        limit = 0,
        offset = 0,
      } = action.payload;
      if(level == 'level1'){
        if(offset == 0){
          state.dataArrangementCompanyBySpecialization = []
        }
        state.dataArrangementCompanyBySpecialization = Array.from(new Set(state.dataArrangementCompanyBySpecialization.concat(dataResults).map(JSON.stringify))).map(JSON.parse)
      }
      if(level == 'level2'){
        // state.level_2_key = levelSelect
        state.dataArrangementCompanyBySpecialization = state.dataArrangementCompanyBySpecialization.map((item,index) => ( 
          item.name == levelSelect ? {
            ...item,
            nextData: Array.from(new Set(item.nextData.concat(dataResults).map(JSON.stringify))).map(JSON.parse),
            offset: limit+offset,
            tot_row_count : state.getCount
          } : {
            ...item, nextData: item.nextData?item.nextData:[]
          }
        ))
      }
      if(level == 'level3'){
        state.dataArrangementCompanyBySpecialization = state.dataArrangementCompanyBySpecialization.map((item,index) => (
          // item.name == state.level_2_key ? {
            {
              ...item,
            nextData: item.nextData.map((Nitem,index) => (
              Nitem.name == levelSelect && Nitem.parent == levelSelectParent  ? {
                ...Nitem,
                nextData: Array.from(new Set(Nitem.nextData.concat(dataResults).map(JSON.stringify))).map(JSON.parse),
                offset: limit+offset,
                tot_row_count : state.getCount
              } : {
                ...Nitem, nextData: Nitem.nextData?Nitem.nextData:[]
              }
            ))
          // } : {
          //   ...item, nextData: item.nextData?item.nextData:[]
          }
        ))
      }
    },
    setDataArrangementCompanyByPhysicians(state, action){
      let {
        dataResults,
        level,
        levelSelect = '',
        levelSelectParent = '',
        limit = 0,
        offset = 0,
      } = action.payload;
      if(level == 'level1'){
        if(offset == 0){
          state.dataArrangementCompanyByPhysicians = []
        }
        state.dataArrangementCompanyByPhysicians = Array.from(new Set(state.dataArrangementCompanyByPhysicians.concat(dataResults).map(JSON.stringify))).map(JSON.parse)
      }
      if(level == 'level2'){
        // state.level_2_key = levelSelect
        state.dataArrangementCompanyByPhysicians = state.dataArrangementCompanyByPhysicians.map((item,index) => ( 
          item.name == levelSelect ? {
            ...item,
            nextData: Array.from(new Set(item.nextData.concat(dataResults).map(JSON.stringify))).map(JSON.parse),
            offset: limit+offset,
            tot_row_count : state.getCount
          } : {
            ...item, nextData: item.nextData?item.nextData:[]
          }
        ))
      }
      if(level == 'level3'){
        state.dataArrangementCompanyByPhysicians = state.dataArrangementCompanyByPhysicians.map((item,index) => (
          // item.name == state.level_2_key ? {
            {
              ...item,
            nextData: item.nextData.map((Nitem,index) => (
              Nitem.name == levelSelect && Nitem.parent == levelSelectParent  ? {
                ...Nitem,
                nextData: Array.from(new Set(Nitem.nextData.concat(dataResults).map(JSON.stringify))).map(JSON.parse),
                offset: limit+offset,
                tot_row_count : state.getCount
              } : {
                ...Nitem, nextData: Nitem.nextData?Nitem.nextData:[]
              }
            ))
          // } : {
          //   ...item, nextData: item.nextData?item.nextData:[]
          }
        ))
      }
    }
  },
});

const { actions } = DiseasesReducer;

export const {
  setDiseaseData,
  setAlphabetClicked,
  setSourceLoading,
  toggleListDiseasesModal,
  setDiseasesModalData,
  setDiseasesAlphabetically,
  setDiseasesSearchResult,
  setAlphabets,
  setPeopleData,
  setPlayersData,
  setAllPlayersList,
  setPlayersSummaryData,
  setPlayersByCompanyData,
  setPlayersByCategoryData,
  setLandmarkTrialsData,
  setModalityData,
  setPhysiciansByCompany,
  setLoading,
  setDataArrangement,
  setDataArrangementCompanyBySpecialization,
  setDataArrangementCompanyByPhysicians,
  setStaticLoading,
  setSearching,
  setCount,
  setGrandParentCount,
  setPhysiciansByCompanyDispatchLevelLeaf,
  setPlayersSummaryDataLevelLeaf,
  setAllCategoryList,
  setComapnyData,
  setCompanyPipelineCategory,
  setSinglePhysician,
  setPhysiciansYears,
  setDealSummaryData,
  setPhysiciansSummaryData,
  setCompanySummaryData,
} = actions;

export const GetListOfDiseasesAlphabeticallyDispatch =
  (id) => async (dispatch) => {
    try {
      dispatch(setSourceLoading(true));
      let url = `${allDiseasesAlphabetically}`;
      if(id){
        url = `${allDiseasesAlphabetically}?user_id=${id}`
      }
      const { data } = await Axios.get(url, config);
      console.log(data);
      const map = new Map(Object.entries(data));
      const alphabets = map.keys();
      console.log(map);
      dispatch(setAlphabets([...alphabets]));
      dispatch(setDiseasesAlphabetically(map));
      dispatch(setSourceLoading(false));
    } catch (error) {
      console.log(error);
    }
  };

export const SearchDiseasesDispatch = (searchItem, user_id) => async (dispatch) => {
  if (searchItem.length <= 0) {
    return null;
  }
  let url = `${searchConditions}?search=${searchItem}`;
  // get userpermissions from localstorage
  let userpermissions = JSON.parse(localStorage.getItem('userpermissions'));
  // loop through userpermissions and check if is_superuser key is true
  let is_superuser = userpermissions.filter((item) => item.is_superuser === true);
  if(user_id && is_superuser.length === 0){
    url = `${searchConditions}?search=${searchItem}&user_id=${user_id}`;
  }
  try {
    dispatch(setSearching(true));
    dispatch(setDiseasesSearchResult({}));
    const { data } = await Axios.get(
      url,
      config
    );
    dispatch(setDiseasesSearchResult(data));
    dispatch(setSearching(false));
  } catch (error) {
    console.log(error);
  }
};

export const GetPeopleDispatch = (condition_id, searchItems) => async (dispatch) => {
  // if (condition_id <= 0) {
  //   return null;
  // }
  try {
    let search = serialize(searchItems)
    dispatch(setStaticLoading(true));
    let url = ""
    if(condition_id){
      url = `${getPeopleByCondition}/${condition_id}`+search
    }else{
      url = `${getPeopleByCondition}`+search
    }
    const { data } = await Axios.get(
      url,
      config
    );
    dispatch(setPeopleData(data));
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const GetPlayersDispatch = (condition_id) => async (dispatch) => {
  if (condition_id <= 0) {
    return null;
  }
  let url = getPlayersByCondition + "/" + condition_id;
  try {
    dispatch(setLoading(true));
    const { data } = await Axios.get(url, config);
    dispatch(setPlayersData(data));
    dispatch(setLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const GetAllPlayersListDispatch = (condition_id, type) => async (dispatch) => {
  if (condition_id <= 0) {
    return null;
  }
  let url = getPlayersByCondition + "/" + condition_id + (type ? "/" + type : "");
  try {
    dispatch(setLoading(true));
    const { data } = await Axios.get(url, config);
    dispatch(setAllPlayersList(data));
    dispatch(setLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const GetAllCategoryListDispatch = (condition_id, type) => async (dispatch) => {
  if (condition_id <= 0) {
    return null;
  }
  let url = getPlayersByCondition + "/" + condition_id + (type ? "/" + type : "");
  try {
    dispatch(setLoading(true));
    const { data } = await Axios.get(url, config);
    dispatch(setAllCategoryList(data));
    dispatch(setLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const GetPlayersSummaryDispatch = (condition_id, type, searchItems) => async (dispatch) => {
  let search = serialize(searchItems)
  if (condition_id <= 0) {
    return null;
  }
  let url = getPlayersByCondition + "/" + condition_id + (type ? "/" + type : "")+search;
  try {
    dispatch(setStaticLoading(true));
    const { data } = await Axios.get(url, config);
    dispatch(setPlayersSummaryData(data));
    dispatch(setCount(data.count))
    if(searchItems.level == 'level1'){dispatch(setGrandParentCount(data.count))}
    const dataResults = data.data;
      const level = searchItems.level?searchItems.level:'';
      const levelSelect = searchItems.filter_by?searchItems.filter_by:'';
      const levelSelectParent = searchItems.filter_by_2?searchItems.filter_by_2:'';
      const limit = searchItems.limit?searchItems.limit:0;
      const offset = searchItems.offset?searchItems.offset:0;
    dispatch(setDataArrangement({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const GetPlayersSummaryDispatchLevelLeaf = (condition_id, type, searchItems) => async (dispatch) => {
  let search = serialize(searchItems)
  if (condition_id <= 0) {
    return null;
  }
  let url = getPlayersByCondition + "/" + condition_id + (type ? "/" + type : "")+search;
  try {
    dispatch(setStaticLoading(true));
    const { data } = await Axios.get(url, config);
    dispatch(setPlayersSummaryDataLevelLeaf(data));
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const GetPlayersByCategoryDispatch = (condition_id, type) => async (dispatch) => {
  if (condition_id <= 0) {
    return null;
  }
  let url = getPlayersByCondition + "/" + condition_id + (type ? "/" + type : "");
  try {
    dispatch(setLoading(true));
    const { data } = await Axios.get(url, config);
    dispatch(setPlayersByCategoryData(data));
    dispatch(setLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const GetPlayersByCompanyDispatch = (condition_id, type, companyName) => async (dispatch) => {
  if (condition_id <= 0) {
    return null;
  }
  let url = getPlayersByCondition + "/" + condition_id + (type ? "/" + type : "") + (companyName ? "/" + companyName : "");
  try {
    dispatch(setLoading(true));
    const { data } = await Axios.get(url, config);
    dispatch(setPlayersByCompanyData(data));
    dispatch(setLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const GetLandmarkTrialsDispatch = () => async (dispatch) => {
  try {
    let data = require('./landmarktrials.json');
    dispatch(setLandmarkTrialsData(data));
  } catch (error) {
    console.log(error);
  }
};

export const GetModalityDispatch = () => async (dispatch) => {
  try {
    let data = require('./modality.json');
    dispatch(setModalityData(data));
  } catch (error) {
    console.log(error);
  }
};

export const GetPhysiciansByCompanyDispatch = (response_type,searchItems) => async (dispatch) => {

  let search = serialize(searchItems)
  let url = getPhysiciansByCompany;
  if (response_type) {
    url = url + "/" + response_type+search;
  }
  try {
    dispatch(setStaticLoading(true));
    const { data } = await Axios.get(url, config);
    dispatch(setPhysiciansByCompany(data.data));
    dispatch(setCount(data.count))
    dispatch(setPhysiciansYears(data.years))
    if(searchItems.level == 'level1'){dispatch(setGrandParentCount(data.count))}
      const dataResults = data.data;
      const level = searchItems.level?searchItems.level:'';
      const levelSelect = searchItems.filter_by?searchItems.filter_by:'';
      const levelSelectParent = searchItems.filter_by_2?searchItems.filter_by_2:'';
      const limit = searchItems.limit?searchItems.limit:0;
      const offset = searchItems.offset?searchItems.offset:0;
    if(response_type=='company_by_specialization'){
      dispatch(setDataArrangementCompanyBySpecialization({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    }else if(response_type=='company_by_physicians'){
      dispatch(setDataArrangementCompanyByPhysicians({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    }
    dispatch(setDataArrangement({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
}

export const GetPhysiciansByCompanyDispatchLevelLeaf = (response_type,searchItems) => async (dispatch) => {

  let search = serialize(searchItems)
  let url = getPhysiciansByCompany;
  if (response_type) {
    url = url + "/" + response_type+search;
  }
  try {
    dispatch(setStaticLoading(true));
    const { data } = await Axios.get(url, config);
    dispatch(setPhysiciansByCompanyDispatchLevelLeaf(data.data));
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
}

export const GetPlayersAllDispatch = (player_id, searchItems, type="") => async (dispatch) => {
  let search = serialize(searchItems)
  if (player_id <= 0) {
    return null;
  }
  let url = getPlayers + "/" + player_id + (type ? "/" + type : "") +search;
  try {
    dispatch(setStaticLoading(true));
    const { data } = await Axios.get(url, config);
    dispatch(setPlayersSummaryData(data));
    dispatch(setCount(data.count))
    if(searchItems.level == 'level1'){dispatch(setGrandParentCount(data.count))}
    const dataResults = data.data;
      const level = searchItems.level?searchItems.level:'';
      const levelSelect = searchItems.filter_by?searchItems.filter_by:'';
      const levelSelectParent = searchItems.filter_by_2?searchItems.filter_by_2:'';
      const limit = searchItems.limit?searchItems.limit:0;
      const offset = searchItems.offset?searchItems.offset:0;
    dispatch(setDataArrangement({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const GetPlayersAllDispatchLevelLeaf = (player_id, searchItems) => async (dispatch) => {
  let search = serialize(searchItems)
  if (player_id <= 0) {
    return null;
  }
  let url = getPlayers + "/" + player_id + search;
  try {
    dispatch(setStaticLoading(true));
    const { data } = await Axios.get(url, config);
    dispatch(setPlayersSummaryDataLevelLeaf(data));
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const GetCompanyPipelineCategoryDispatch = (searchItems) => async (dispatch) => {
  let search = serialize(searchItems)
  let url = getCompanyPipeline+search
  try {
    dispatch(setStaticLoading(true));
    const { data } = await Axios.get(url, config);
    dispatch(setCompanyPipelineCategory(data));
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const GetAllPlayersCompanyListDispatch = (player_id, type) => async (dispatch) => {
  let url = getPlayers + (player_id ? "/" + player_id : "") + (type ? "/" + type : "");
  try {
    dispatch(setLoading(true));
    const { data } = await Axios.get(url, config);
    dispatch(setAllPlayersList(data));
    dispatch(setLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const GetAllCategoryCompanyListDispatch = (player_id, type) => async (dispatch) => {
  let url = getPlayers + (player_id ? "/" + player_id : "") + (type ? "/" + type : "");
  try {
    dispatch(setLoading(true));
    const { data } = await Axios.get(url, config);
    dispatch(setAllCategoryList(data));
    dispatch(setLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getDealsAllDispatch = (searchItems) => async (dispatch) => {
  let search = serialize(searchItems)
  let url = getDeals + search;
  try {
    dispatch(setStaticLoading(true));
    const { data } = await Axios.get(url, config);
    dispatch(setDealSummaryData(data));
    dispatch(setCount(data.count))
    if(searchItems.level == 'level1'){dispatch(setGrandParentCount(data.count))}
    const dataResults = data.data;
      const level = searchItems.level?searchItems.level:'';
      const levelSelect = searchItems.filter_by?searchItems.filter_by:'';
      const levelSelectParent = searchItems.filter_by_2?searchItems.filter_by_2:'';
      const limit = searchItems.limit?searchItems.limit:0;
      const offset = searchItems.offset?searchItems.offset:0;
    dispatch(setDataArrangement({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getSingleDealDispatch = (company_id, searchItems) => async (dispatch) => {
  let search = serialize(searchItems)
  let url = getDeals + (company_id ? "/" + company_id : "") + search;
  try {
    dispatch(setLoading(true));
    const { data } = await Axios.get(url, config);
    dispatch(setDealSummaryData(data));
    dispatch(setLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getDealsDispatchLevelLeaf = (searchItems) => async (dispatch) => {
  let search = serialize(searchItems)
  let url = getDeals + search;
  try {
    dispatch(setStaticLoading(true));
    const { data } = await Axios.get(url, config);
    dispatch(setPlayersSummaryDataLevelLeaf(data));
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getCompanyDispatch = (company_id) => async (dispatch) => {
  let url = ""
  if(company_id){
    url = getCompany + (company_id ? "/" + company_id : "");
  }else{
    url = getCompany;
  }
  try {
    // dispatch(setStaticLoading(true));
    const { data } = await Axios.get(url, config);
    dispatch(setComapnyData(data));
    // dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getAllCompanyDispatch = (searchItems) => async (dispatch) => {
  let search = serialize(searchItems)
  let url = getCompany+search;
  try {
    dispatch(setStaticLoading(true));
    const { data } = await Axios.get(url, config);
    dispatch(setCompanySummaryData(data));
    const dataResults = data.data;
    dispatch(setCount(data.count))
      const level = searchItems.level?searchItems.level:'';
      const levelSelect = searchItems.filter_by?searchItems.filter_by:'';
      const levelSelectParent = searchItems.filter_by_2?searchItems.filter_by_2:'';
      const limit = searchItems.limit?searchItems.limit:0;
      const offset = searchItems.offset?searchItems.offset:0;
    dispatch(setDataArrangement({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getSingleCompanyDispatch = (company_id, searchItems) => async (dispatch) => {
  let search = serialize(searchItems)
  let url = getCompany + (company_id ? "/" + company_id : "") + search;
  try {
    dispatch(setLoading(true));
    const { data } = await Axios.get(url, config);
    dispatch(setCompanySummaryData(data));
    dispatch(setLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const GetAllCompanyListDispatch = (searchItems, type) => async (dispatch) => {
  let url = getCompany+"/type"+ (type ? "/" + type : "");
  let search = serialize(searchItems)
  url = url+search
  try {
    dispatch(setLoading(true));
    const { data } = await Axios.get(url, config);
    dispatch(setAllPlayersList(data));
    dispatch(setLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getAllPhysiciansDispatch = (searchItems) => async (dispatch) => {
  let search = serialize(searchItems)
  let url = getPhysicians+search;
  try {
    dispatch(setStaticLoading(true));
    const { data } = await Axios.get(url, config);
    dispatch(setPhysiciansSummaryData(data));
    const dataResults = data.results;
    dispatch(setCount(data.count))
      const level = searchItems.level?searchItems.level:'';
      const levelSelect = searchItems.filter_by?searchItems.filter_by:'';
      const levelSelectParent = searchItems.filter_by_2?searchItems.filter_by_2:'';
      const limit = searchItems.limit?searchItems.limit:0;
      const offset = searchItems.offset?searchItems.offset:0;
    dispatch(setDataArrangement({ dataResults, level, levelSelect, levelSelectParent, limit, offset}))
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const getSinglePhysicianDispatch = (physician_id, searchItems) => async (dispatch) => {
  let url = getPhysicians + (physician_id ? "/" + physician_id : "");
  let search = serialize(searchItems)
  url = url+search
  try {
    dispatch(setStaticLoading(true));
    const { data } = await Axios.get(url, config);
    dispatch(setSinglePhysician(data));
    dispatch(setStaticLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export default DiseasesReducer;
